<template>
  <div class="acceptance-box">
    <div class="steps-box">
      <view-card title="工单受理">
        <div slot="content" class="content">
          <steps :active="active" :steps="steps"></steps>
        </div>
      </view-card>
    </div>
    <div class="operate-box">
      <template v-if="active == 1">
        <div class="operate-item-box">
          <template v-if="!isVerified">
            <div class="verified-box">
              <div class="main">
                <span>请填写器具机身上的条形码号码</span>
                <div>
                  <el-input class="code-input" v-model="machineCode"></el-input>
                </div>
              </div>
              <button @click="verifyMachineCode" type="primary" class="btn-big primary-button">
                确定
              </button>
            </div>
          </template>
          <template v-else>
            <div class="info-box">
              <div class="tip">
                <span>待检器具信息与工单一致，请继续下一步</span>
              </div>
              <div class="info-content">
                <div v-for="(item, index) of machInfos" :key="index" class="info-item"
                  :class="{ uneditable: !isEditable, editable: !isEditable }">
                  <div class="label">
                    <span>{{ item.label }}</span>
                  </div>
                  <div class="content">
                    {{ machInfo[item.key] }}
                  </div>
                </div>
              </div>
              <div class="button-box">
                <button @click="goToNextStep" class="btn-big primary-button">
                  下一步
                </button>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-if="active == 2">
        <div class="operate-item-box">
          <div class="select-record-box">
            <div class="select-box" v-if="!isContinueEditing">
              <span>报告本模板</span>
              <el-select v-model="recordSelected" placeholder="请选择报告本模板" class="recordSelect">
                <el-option v-for="item in recoreList" :key="item.zexcelId" :label="item.zfileName" :value="item.zfileDir">
                </el-option>
              </el-select>
              <button class="openExcelModelBtn" @click="openExcelModel">{{ "打开模板" }}</button>
            </div>
            <div v-else>
              <div style="cursor: pointer;display: flex;flex-direction: column;row-gap: 8px;align-items: center;"
                @click="continueEditorExcel">
                <svg-icon icon-class="editor" :size="5"></svg-icon>
                <div><span>{{ "点击继续编辑工单内容" }}</span></div>
              </div>
            </div>
          </div>
          <div class="button-box">
            <button class="btn-normal primary-button" @click="goToPreviousStep" v-if="!isContinueEditing">
              上一步
            </button>
            <button class="btn-normal primary-button" @click="goToNextStep">
              下一步
            </button>
          </div>
        </div>
      </template>
      <template v-if="active == 3">
        <div class="operate-item-box">
          <span>
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import steps from "@/components/Steps";
import Table from "@/components/Table";
import { exWorkOrder, getDetails } from "@/api/order";
import { reList } from "@/api/record";
import { Message, MessageBox } from "element-ui";
const machineInfoKey = {
  businessNo: "业务编号",
  client: "委托单位",
  factoryNo: "出厂编号",
  department: "部门/子公司",
  instrumentName: "仪器名称",
  testingItems: "检测项目",
  specificationsType: "服务类型",
  workTime: "受理日期",
  testingRoom: "检测场所",
  inspectionTime: "送检时间"
};
const STEPS_OPTION = [
  {
    title: "第 1 步",
    description: "待检器具核对",
  },
  {
    title: "第 2 步",
    description: "检测数据记录",
  },
  {
    title: "第 3 步",
    description: "检测报告确认",
  },
  {
    title: "第 4 步",
    description: "加入印章标签",
  },
]; //步骤条配置

export default {
  name: "acceptance",
  components: {
    steps,
    Table,
  },
  data() {
    return {
      id: null,
      orderId: null,
      instrumentName: null,
      steps: STEPS_OPTION,
      active: 1, //当前步骤下标,从1开始
      isVerified: false, //机器码与工单校验结果标注
      machineCode: "", //器具条码,
      //器具信息
      machInfo: {},
      workFlow: 0,
      isEditable: false, //设备信息是否是编辑状态
      recordSelected: null,
      recoreList: [],
      excelSavaResult: false,
      savaAsPDFResult: false,
      isContinueEditing: false,
      isFinshStep4: false
    };
  },
  computed: {
    machInfos() {
      let items = [];
      let labelKeys = Object.keys(machineInfoKey);
      for (let key in this.machInfo) {
        if (labelKeys.includes(key)) {
          items.push({
            key: key,
            label: machineInfoKey[key],
            value: this.machInfo[key],
          });
        }

      }
      return items;
    },
  },
  watch: {
    recordSelected: {
      handler(val) {
        if (val) {
          this.openExcelModel();
        }
      },
    },
    active: {
      handler(val) {
        if (val == 3) {
          this.openExcelSaveAsPDFMOdel();
        }
      },
    },
  },
  methods: {
    verifyMachineCode() {
      getDetails({ businessNo: this.machineCode, id: this.id }).then((res) => {
        if (res.data) {
          this.machInfo = res.data;
          this.isVerified = true;
        } else {
          this.$modal.msgWarning("设备不匹配");
        }
      }).catch();
    },
    getOrderDetailsById() {
      getDetails({ id: this.id }).then((res) => {
        this.machInfo = res.data;
      });
    },
    async goToNextStep() {
      if (this.active == 2 && !this.excelSavaResult) {
        Message("当前未保存Excel,请重新选择");
        return;
      }
      this.active++;
    },
    goToPreviousStep() {
      this.active--;
    },
    Fullscreen() {
      this.$refs.editor.requestFullscreen();
    },
    submit() {
      exWorkOrder({ id: this.id, workStatus: 3 })
        .then((res) => {
          Message("已完成受理");
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getReList() {
      reList().then((res) => {
        this.recoreList = res.data;
      });
    },
    getItem(id) {
      return this.recoreList.filter((item) => item.id == id);
    },
    openExcelModel() {
      if (!this.recordSelected) {
        this.$modal.msgWarning("当前未选择报告模板");
        return;
      }
      POBrowser.openWindow(
        "/PgOffice/orderExcel",
        "width=1600px;height=1000px;",
        encodeURI(
          JSON.stringify({
            zfileDir: this.recordSelected,
            orderId: this.id,
            id: this.id,
            instrumentName: decodeURI(this.instrumentName),
            ableGetValue: ["精密压力表", "介质损耗测试仪"].includes(decodeURIComponent(this.instrumentName))
          })
        )
      );
    },
    openExcelSaveAsPDFMOdel() {
      POBrowser.openWindow(
        "/PgOffice/editorExcel",
        "width=980px;height=800px;",
        JSON.stringify({ orderId: this.id })
      );
      window.PageOfficeCbFn = (value) => {
        this.savaAsPDFResult = true;
        this.savaAsPDFHandler(value);
      };
      window.PageOfficeCloseFn = (value) => {
        this.savaAsPDFHandler();
      };
    },
    continueEditorExcel: function () {
      POBrowser.openWindow(
        "/PgOffice/continueEditorOrderExcel",
        "width=980px;height=800px;",
        JSON.stringify({ excelDir: this.machInfo.excelDir, orderId: this.id })
      );
      window.PageOfficeCbFn = () => {
        MessageBox.confirm("检测数据记录完成,请点击下一步确认报告模板", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "暂存当前进度",
          type: "info",
        }).then(async () => {
          await exWorkOrder({ id: this.id, workStatus: 2 })
            .then((res) => {
            })
            .catch((e) => {
              console.error(e);
            });
        }).catch(() => {
          this.$router.push({ name: "orderPendding" });
        });
      };
      window.PageOfficeCloseFn = () => { };
    },

    async savaAsPDFHandler(value) {
      if (this.savaAsPDFResult) {
        this.active++;
        await exWorkOrder({ id: this.id, workStatus: 2, workFlow: 3 })
          .then((res) => {
          })
          .catch((e) => {
            console.error(e);
          });
        this.openWord(value);
      } else {
        MessageBox.confirm("当前工单未保存,是否暂存当前状态", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "重新编辑",
          type: "info",
        }).then(() => {
          this.$router.push({ path: "/orderManagement/pendding" });
        }).catch(() => {
          this.openExcelSaveAsPDFMOdel();
        });
      }
    },
    initPageOfficeCallBackFn: function () {
      window.PageOfficeCbFn = async (e) => {
        this.excelSavaResult = true;
        MessageBox.confirm("检测数据记录完成,请点击下一步确认报告模板", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "暂存当前进度",
          type: "info",
        }).then(async () => {
          await exWorkOrder({ id: this.id, workStatus: 2 })
            .then((res) => {
            })
            .catch((e) => {
              console.error(e);
            });
        }).catch(() => {
          this.$router.push({ name: "orderPendding" });
        });
      };
    },
    openWord: function (value) {
      POBrowser.openWindow(
        "/PgOffice/simpleWord",
        "width=980px;height=800px;",
        JSON.stringify({ orderId: this.id, fileDir: decodeURIComponent(value) })
      );
      window.PageOfficeCbFn = () => {
        this.completeHandler();
      };
      window.PageOfficeCloseFn = () => {
        this.$router.push({ name: "orderPendding" });
      };
    },
    completeHandler: async function () {
      Message("工单受理完成,正在跳转。");
      await this.submit();
      this.$router.push({ name: "orderPendding" });
    }
  },
  created() {
    let params = this.$route.params;
    let { id, instrumentName, workFlow, data } = params;
    if (!id || !instrumentName || instrumentName == undefined || id == undefined) {
      Message("当前状态异常,正在跳转");
      setTimeout(() => {
        this.$router.go(-1);
      }, 1000);
      return;
    }
    this.id = id;
    this.instrumentName = instrumentName;
    this.workFlow = workFlow;
    if (workFlow != 0) {
      this.machInfo = data;
    }
    if (workFlow === 1) {
      this.isVerified = true;
    } else if (workFlow == 2) {
      this.isVerified = true;
      this.isContinueEditing = true;
      this.excelSavaResult = true;
      this.active = 2;
    } else if (workFlow === 3) {
      this.isVerified = true;
      this.isContinueEditing = true;
      this.excelSavaResult = true;
      this.savaAsPDFResult = true;
      this.active = 4;
      this.openWord(data.wordDir);
    }
    if (workFlow != 3) {
      this.initPageOfficeCallBackFn();
      this.getReList();
    }
  },
  beforeDestroy() {
    window.PageOfficeCbFn = () => { };
  },
}
</script>

<style lang="less" scoped>
.code-input {
  width: 600px;
  height: 50px;
}

.acceptance-box {
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 2%;
  font-size: 19px;

  .steps-box {
    height: 30%;
    background-color: #ffffff;
    border-radius: 10px;

    .header {
      height: 25%;
      border-bottom: 1px solid #bbbbbb;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 20px;
      color: #000000;
      padding: 0 1%;
      box-sizing: border-box;
    }

    .content {
      display: flex;
      align-items: center;

      height: 75%;
    }
  }

  .operate-box {
    height: 68%;
    background-color: #ffffff;
    border-radius: 10px;

    .operate-item-box {
      height: 100%;

      padding: 1% 5%;
      box-sizing: border-box;
      background-color: #ffffff;

      .verified-box {
        height: 100%;

        padding: 5% 0;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-around;

        .main {
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: column;
          gap: 20px;

          span {
            color: #0014b7;
            font-size: 18px;
          }
        }
      }

      .info-box {
        height: 100%;

        box-sizing: border-box;

        .tip {
          display: flex;
          justify-content: space-between;
          height: 8%;

          color: #3c46f4;
          font-size: 18px;
          font-weight: 600;

          .editor-button {
            border: none;
            color: #0014b7;
          }
        }

        .info-content {
          height: 80%;
          border-top: 1px solid #bbbbbb;
          border-left: 1px solid #bbbbbb;
          display: flex;
          flex-flow: column wrap;
          display: grid;
          grid-template-columns: 50% 50%;

          .info-item {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            border-bottom: 1px solid #bbbbbb;
            border-right: 1px solid #bbbbbb;

            .label {
              border-right: 1px solid #bbbbbb;
              height: 100%;
              width: 30%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: var(--fontSizeBig);
              font-weight: bold;
            }

            .content {
              width: 70%;
              height: 100%;
              padding: 1%;
              display: flex;
              align-items: center;
              justify-content: center;

              .el-input {
                font-size: var(--fontSizeBig);
              }
            }
          }
        }

        .button-box {
          margin-top: 20px;
        }
      }

      .select-record-box {
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;

        .select-box {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }

      .editor-label {
        height: 10%;

        display: flex;
        font-size: 18px;
        align-items: center;
        justify-content: space-between;
      }

      .editor-box {
        margin: 20px 0;
        height: 60%;
        background-color: #ffffff;
        border: 1px solid #bbbbbb;
      }
    }
  }

  .button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 20px;
  }

  .edit-button {
    width: 90px;
    height: 31px;
    background-color: transparent;
    color: #3c46f4;
    border: none;
    font-size: var(--fontSizeBig);
    font-weight: bold;
    cursor: pointer;
  }
}

.openExcelModelBtn {
  width: 120px;
  height: 40px;
  border: none;
  cursor: pointer;
}
</style>
